<template>
  <div class="startPc">
    <div class="banner">
      <div class="bannerPosition">
        <!-- <img class="code" src="/activity/startPage/code.png" alt=""> -->
        <img src="../../../../static/appDownload/code.png" class="code" alt="">
        <div>
          <img @click="downLoadIos" src="/activity/startPage/iosButton.png" alt="">
          <img @click="downLoadAndroid" src="/activity/startPage/androidButton.png" alt="">
        </div>
      </div>
    </div>
    <div class="second">
      <img src="/activity/startPage/red.png" alt="">
      <img class="secondMarginImg" src="/activity/startPage/secondContent.png" alt="">
      <div class="swiperBox">
        <img class="secondTitle" src="/activity/startPage/secondTitle.png" alt="">
        <!-- Swiper -->
        <div class="swiper-container swiperOne">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <img src="/activity/startPage/swiperLeft.png" alt="">
            </div>
            <div class="swiper-slide">
              <img src="/activity/startPage/swiperCenter.png" alt="">
            </div>
            <div class="swiper-slide">
              <img src="/activity/startPage/swiperRight.png" alt="">
            </div>
	        </div>
        </div>
        <img class="blue left1" src="/activity/startPage/blue1.png" alt="">
        <img class="blue left2" src="/activity/startPage/blue2.png" alt="">
        <img class="blue left3" src="/activity/startPage/blue3.png" alt="">
        <img class="blue right1" src="/activity/startPage/blue4.png" alt="">
        <img class="blue right2" src="/activity/startPage/blue5.png" alt="">
        <img class="blue right3" src="/activity/startPage/blue6.png" alt="">
      </div>
    </div>
    <div class="third">
      <img src="/activity/startPage/thirdImg.png" alt="">
      <img src="/activity/startPage/txt1.png" alt="">
    </div>
    <div class="fourth">
      <!-- <img src="/activity/startPage/txt2.png" alt=""> -->
      <div class="model_text">
        <h2>积分当<span>钱</span>花</h2>
        <p>无固定账户层级，用户自己<span>攒积分</span>升级</p>
        <p>每升一级，<span>每手返赠越高</span>，越交易<span>越省钱</span></p>
      </div>
      <img src="/activity/startPage/fourthImg.png" alt="">
    </div>
    <div class="fifth">
      <img src="/activity/startPage/fifthImg.png" alt="">
      <!-- <img src="/activity/startPage/txt3.png" alt=""> -->
      <div class="model_text" style="text-align:right; margin-left:-260px;">
        <h2>美元赠金<span>额外兑换</span></h2>
        <p>当月交易越多，每手<span>返还越高</span></p>
        <p>美元赠金<span>不分账户等级</span>，只按<span>交易手数</span>计算</p>
      </div>
    </div>
    <div class="sixth">
      <img class="sixthMarginImg" src="/activity/startPage/sixTit.png" alt="">
      <img src="/activity/startPage/sixTit2.png" alt="">
      <div class="sixContent">
        <p>
          <img src="/activity/startPage/sixImg1.png" alt="">
          <img src="/activity/startPage/sixImg2.png" alt="">
          <img src="/activity/startPage/sixImg3.png" alt="">
        </p>
        <div class="bottomSwiper">
          <!-- swiperTwo -->
          <div class="swiper-container swiperTwo">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <img src="/activity/startPage/sixBanner.png" alt="">
              </div>
              <div class="swiper-slide">
                <img src="/activity/startPage/sixBanner2.png" alt="">
              </div>
              <div class="swiper-slide">
                <img src="/activity/startPage/sixBanner3.png" alt="">
              </div>
              <div class="swiper-slide">
                <img src="/activity/startPage/sixBanner4.png" alt="">
              </div>
              <div class="swiper-slide">
                <img src="/activity/startPage/sixBanner5.png" alt="">
              </div>
            </div>
            <!-- 如果需要按钮 -->
            <div class="swiper-button-next"></div>
            <div class="swiper-button-prev"></div>
          </div>
          <img class="iphoneImg" src="/activity/startPage/iphonexBackground.png" alt="">
        </div>
        <p>
          <img src="/activity/startPage/sixImg4.png" alt="">
          <img src="/activity/startPage/sixImg5.png" alt="">
          <img src="/activity/startPage/sixImg6.png" alt="">
        </p>
      </div>
      <img class="sixthMarginImg" src="/activity/startPage/sixTit3.png" alt="">
      <img src="/activity/startPage/sixTit4.png" alt="">
    </div>
    <div class="seventh">
      <img src="/activity/startPage/sevenTit.png" alt="">
      <div class="sevenContent">
        <p>
          <!-- <img src="/activity/startPage/code.png" alt=""> -->
          <img src="../../../../static/appDownload/code.png" alt="">
          <span>手机扫码 &nbsp;&nbsp; 即刻下载</span>
        </p>
        <p>
          <img @click="downLoadIos" src="/activity/startPage/bottomIos.png" alt="">
          <img @click="downLoadAndroid" src="/activity/startPage/bottomAndroid.png" alt="">
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper'
export default {
  name:'startPc',
  data(){
    return {
    }
  },
  created(){
    // 传参控制显示顶部和底部
    this.$emit('controlShow', false);
    if(document.body.clientWidth<500){
      window.location.href = 'https://m.jvjtjqr.com/#/activity/startPage/wap';
    }
  },
  methods:{
    downLoadIos(){
      // window.location.href = 'https://apps.apple.com/cn/app/%E5%AE%89%E8%83%9C%E8%B4%A2%E5%AF%8C/id1602800589';
      window.location.href = 'https://apps.apple.com/cn/app/id1598072476';
    },
    downLoadAndroid(){
      // window.location.href = 'https://oss.0790jiaxiao.com/HS/software/hs_9999.apk';
      window.location.href = 'https://oss.0790jiaxiao.com/hsnew/apk/2.9.2/red_lionRelease-V2.9.2_9999.apk';
    },
  },
  mounted(){
    new Swiper ('.swiperOne', {
      slidesPerView: 3,
      spaceBetween: 0,
      centeredSlides: true,
      grabCursor:true,
      loop: true,
      autoplay: {
        delay: 3000,//时间 毫秒
        disableOnInteraction: false,//用户操作之后是否停止自动轮播默认true
      },
    });
    new Swiper ('.swiperTwo', {
      slidesPerView: 1,
      spaceBetween: 0,
      centeredSlides: true,
      grabCursor:true,
      loop: true,
      autoplay: {
        delay: 3000,//时间 毫秒
        disableOnInteraction: false,//用户操作之后是否停止自动轮播默认true
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    })
  }
}
</script>

<style lang="less" scoped>
  .startPc{
    width: 100%;
    .banner{
      width:100%;
      height: 810px;
      background: url('/activity/startPage/banner.png') no-repeat;
      background-size: cover;
      position: relative;
      .bannerPosition{
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 100px;
        left: 17%;
        .code{
          width: 210px;
          height: auto;
          margin-right: 35px;
        }
        div{
          height: 100%;
          display: flex;
          flex-direction: column;
          img{
            margin: 10px 0;
          }
        }
      }
    }
    .second{
      width: 100%;
      height: 1960px;
      background: url('/activity/startPage/secondBackground.png') no-repeat;
      background-size: cover;
      box-sizing: border-box;
      padding-top: 100px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      position: relative;
      .secondMarginImg{
        margin: 60px auto;
      }
      .swiperBox{
        width: 60%;
        margin: 0 auto;
        .secondTitle{
          display: block;
          margin: 0 auto;
        }
        .swiperOne{
          width: 100%;
          height: 100%;
          .swiper-slide {
            height: 1000px;
            text-align: center;
            font-size: 18px;
            /* Center slide text vertically */
            display: -webkit-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            -webkit-justify-content: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            -webkit-align-items: center;
            align-items: center;
            transition: 300ms;
            transform: scale(0.8);
            img{
              width: 460px;
              height: auto;
              opacity: .6;
            }
          }
          .swiper-slide-active,.swiper-slide-duplicate-active{
            transform: scale(1);
            img{
              opacity: 1;
            }
          }
        }
        .blue{
          position: absolute;
          z-index: 2;
        }
        .left1{
          top:61%;
          left: 15%;
        }
        .left2{
          top:72%;
          left: 10%;
        }
        .left3{
          top:82%;
          left: 15%;
        }
        .right1{
          top: 61%;
          right: 15%;
        }
        .right2{
          top: 72%;
          right: 10%;
        }
        .right3{
          top:82%;
          right: 15%;
        }
      }
    }
    .third{
      width: 100%;
      height: 1080px;
      background: url('/activity/startPage/thirdBackground.png') no-repeat;
      background-size: cover;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .fourth{
      width: 100%;
      height: 1080px;
      background: url('/activity/startPage/fourthBackground.png') no-repeat;
      background-size: cover;
      display: flex;
      justify-content: center;
      align-items: center;
      img:nth-child(1){
        margin-right: 50px;
      }
    }
    .fifth{
      width: 100%;
      height: 1080px;
      background: url('/activity/startPage/fifthBackground.png') no-repeat;
      background-size: cover;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .sixth{
      width: 100%;
      height: 1080px;
      background: url('/activity/startPage/sixBackground.png') no-repeat;
      background-size: cover;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      .sixthMarginImg{
        margin-bottom: 35px;
      }
      .sixContent{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 25px;
        p{
          height: 80%;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
        }
        .bottomSwiper{
          width: 600px;
          position: relative;
          .swiperTwo{
            width: 550px;
            .swiper-slide{
              width: 550px;
              text-align: center;
              img{
                width: 340px;
                height: 630px;
              }
            }
            .swiper-button-next{
              width: 80px;
              height: 80px;
              background: url('/activity/startPage/arrowRight.png');
              background-size: cover;
            }
            .swiper-button-prev{
              width: 80px;
              height: 80px;
              background: url('/activity/startPage/arrowLeft.png');
              background-size: cover;
            }
          }
          .iphoneImg{
            width: 315px;
            height: auto;
            position: absolute;
            top: 0;
            left: 24%;
          }
        }
      }
    }
    .seventh{
      width: 100%;
      height: 700px;
      background: url('/activity/startPage/sevenBackground.png') no-repeat;
      background-size: cover;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .sevenContent{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 80px;
        p{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin: 0 60px;
        }
        p:nth-child(1){
          img{
            width: 260px;
            height: 260px;
          }
          span{
            font-size: 28px;
            color: #fff;
          }
        }
        p:nth-child(2){
          height: 290px;
          justify-content: space-between;
        }
      }
    }
    .model_text {
      h2 {
        font-size: 64px;
        margin: 30px 0;
        color: #272c47;
      }
      span {
        color: #b1874c;
      }
      p {
        color: #727272;
        font-size: 48px;
        margin: 30px 0;
      }
    }
  }
</style>
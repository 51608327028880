var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"startPc"},[_c('div',{staticClass:"banner"},[_c('div',{staticClass:"bannerPosition"},[_c('img',{staticClass:"code",attrs:{"src":require("../../../../static/appDownload/code.png"),"alt":""}}),_c('div',[_c('img',{attrs:{"src":"/activity/startPage/iosButton.png","alt":""},on:{"click":_vm.downLoadIos}}),_c('img',{attrs:{"src":"/activity/startPage/androidButton.png","alt":""},on:{"click":_vm.downLoadAndroid}})])])]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_c('div',{staticClass:"seventh"},[_c('img',{attrs:{"src":"/activity/startPage/sevenTit.png","alt":""}}),_c('div',{staticClass:"sevenContent"},[_vm._m(5),_c('p',[_c('img',{attrs:{"src":"/activity/startPage/bottomIos.png","alt":""},on:{"click":_vm.downLoadIos}}),_c('img',{attrs:{"src":"/activity/startPage/bottomAndroid.png","alt":""},on:{"click":_vm.downLoadAndroid}})])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"second"},[_c('img',{attrs:{"src":"/activity/startPage/red.png","alt":""}}),_c('img',{staticClass:"secondMarginImg",attrs:{"src":"/activity/startPage/secondContent.png","alt":""}}),_c('div',{staticClass:"swiperBox"},[_c('img',{staticClass:"secondTitle",attrs:{"src":"/activity/startPage/secondTitle.png","alt":""}}),_c('div',{staticClass:"swiper-container swiperOne"},[_c('div',{staticClass:"swiper-wrapper"},[_c('div',{staticClass:"swiper-slide"},[_c('img',{attrs:{"src":"/activity/startPage/swiperLeft.png","alt":""}})]),_c('div',{staticClass:"swiper-slide"},[_c('img',{attrs:{"src":"/activity/startPage/swiperCenter.png","alt":""}})]),_c('div',{staticClass:"swiper-slide"},[_c('img',{attrs:{"src":"/activity/startPage/swiperRight.png","alt":""}})])])]),_c('img',{staticClass:"blue left1",attrs:{"src":"/activity/startPage/blue1.png","alt":""}}),_c('img',{staticClass:"blue left2",attrs:{"src":"/activity/startPage/blue2.png","alt":""}}),_c('img',{staticClass:"blue left3",attrs:{"src":"/activity/startPage/blue3.png","alt":""}}),_c('img',{staticClass:"blue right1",attrs:{"src":"/activity/startPage/blue4.png","alt":""}}),_c('img',{staticClass:"blue right2",attrs:{"src":"/activity/startPage/blue5.png","alt":""}}),_c('img',{staticClass:"blue right3",attrs:{"src":"/activity/startPage/blue6.png","alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"third"},[_c('img',{attrs:{"src":"/activity/startPage/thirdImg.png","alt":""}}),_c('img',{attrs:{"src":"/activity/startPage/txt1.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fourth"},[_c('div',{staticClass:"model_text"},[_c('h2',[_vm._v("积分当"),_c('span',[_vm._v("钱")]),_vm._v("花")]),_c('p',[_vm._v("无固定账户层级，用户自己"),_c('span',[_vm._v("攒积分")]),_vm._v("升级")]),_c('p',[_vm._v("每升一级，"),_c('span',[_vm._v("每手返赠越高")]),_vm._v("，越交易"),_c('span',[_vm._v("越省钱")])])]),_c('img',{attrs:{"src":"/activity/startPage/fourthImg.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fifth"},[_c('img',{attrs:{"src":"/activity/startPage/fifthImg.png","alt":""}}),_c('div',{staticClass:"model_text",staticStyle:{"text-align":"right","margin-left":"-260px"}},[_c('h2',[_vm._v("美元赠金"),_c('span',[_vm._v("额外兑换")])]),_c('p',[_vm._v("当月交易越多，每手"),_c('span',[_vm._v("返还越高")])]),_c('p',[_vm._v("美元赠金"),_c('span',[_vm._v("不分账户等级")]),_vm._v("，只按"),_c('span',[_vm._v("交易手数")]),_vm._v("计算")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sixth"},[_c('img',{staticClass:"sixthMarginImg",attrs:{"src":"/activity/startPage/sixTit.png","alt":""}}),_c('img',{attrs:{"src":"/activity/startPage/sixTit2.png","alt":""}}),_c('div',{staticClass:"sixContent"},[_c('p',[_c('img',{attrs:{"src":"/activity/startPage/sixImg1.png","alt":""}}),_c('img',{attrs:{"src":"/activity/startPage/sixImg2.png","alt":""}}),_c('img',{attrs:{"src":"/activity/startPage/sixImg3.png","alt":""}})]),_c('div',{staticClass:"bottomSwiper"},[_c('div',{staticClass:"swiper-container swiperTwo"},[_c('div',{staticClass:"swiper-wrapper"},[_c('div',{staticClass:"swiper-slide"},[_c('img',{attrs:{"src":"/activity/startPage/sixBanner.png","alt":""}})]),_c('div',{staticClass:"swiper-slide"},[_c('img',{attrs:{"src":"/activity/startPage/sixBanner2.png","alt":""}})]),_c('div',{staticClass:"swiper-slide"},[_c('img',{attrs:{"src":"/activity/startPage/sixBanner3.png","alt":""}})]),_c('div',{staticClass:"swiper-slide"},[_c('img',{attrs:{"src":"/activity/startPage/sixBanner4.png","alt":""}})]),_c('div',{staticClass:"swiper-slide"},[_c('img',{attrs:{"src":"/activity/startPage/sixBanner5.png","alt":""}})])]),_c('div',{staticClass:"swiper-button-next"}),_c('div',{staticClass:"swiper-button-prev"})]),_c('img',{staticClass:"iphoneImg",attrs:{"src":"/activity/startPage/iphonexBackground.png","alt":""}})]),_c('p',[_c('img',{attrs:{"src":"/activity/startPage/sixImg4.png","alt":""}}),_c('img',{attrs:{"src":"/activity/startPage/sixImg5.png","alt":""}}),_c('img',{attrs:{"src":"/activity/startPage/sixImg6.png","alt":""}})])]),_c('img',{staticClass:"sixthMarginImg",attrs:{"src":"/activity/startPage/sixTit3.png","alt":""}}),_c('img',{attrs:{"src":"/activity/startPage/sixTit4.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('img',{attrs:{"src":require("../../../../static/appDownload/code.png"),"alt":""}}),_c('span',[_vm._v("手机扫码    即刻下载")])])
}]

export { render, staticRenderFns }